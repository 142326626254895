import { Link } from "gatsby"
import React, { Component } from "react"
import { connect } from "react-redux"
import Button from "../components/base/Button"
import Footer from "../components/base/Footer"
import Header from "../components/base/Header"
import SEO from "../components/base/seo"
import LeftContentCard from "../components/marketing/LeftContentCard"
import RightContentCard from "../components/marketing/RightContentCard"
import SubscribeAction from "../components/marketing/SubscribeAction"
import Whatsapp from "../components/marketing/Whatsapp"
import AboutBiodiesel from "../images/about_biodiesel.webp"
import DoorImage from "../images/home_ss14.webp"
import PetronasHero from "../images/petronas_hero.jpg"
import { updatePromotionCode } from "../store/actions/promotionActions"
import { promotionStateInterface } from "../store/reducers/promotionReducer"
import { Language } from "../store/reducers/utilsReducer"

interface Props {
  promotionStore: promotionStateInterface
  updatePromotionCode: (promoCode: string) => void
}

class PetronasPage extends Component<Props> {
  componentDidMount() {
    const searchParams = new URLSearchParams(window.location.search)
    const promoCode = searchParams.get("promo")
    if (promoCode) {
      this.props.updatePromotionCode(promoCode)
    }
  }

  render() {
    let signUpLink = "/auth/signUp"
    if (this.props.promotionStore.promotionCode) {
      signUpLink += `?promo=${this.props.promotionStore.promotionCode}`
    } else {
      signUpLink += "?promo=GAS4OIL"
    }

    return (
      <div className="bg-white">
        <Whatsapp />
        <SEO
          title="Arus Oil - Collaboration with PETRONAS"
          description="Turn your used cooking oil into biodiesel thanks to a partnership between Arus Oil and PETRONAS for a more environmentally friendly future."
        />
        <Header page="PETRONAS Collaboration" />
        <LeftContentCard
          image={PetronasHero}
          title="Recycling Used Cooking Oil Made Easy"
          content={
            "In order to encourage consumers to recycle their used cooking oil (UCO), PETRONAS has partnered with Arus Oil as part of our ongoing efforts to build a greener future. With PETRONAS acting as a collecting point between the consumer and Arus Oil, our group endeavour strives to make recycling UCOs as accessible and straightforward as possible. We do this to build a community that is sustainable, environmentally friendly, and socially responsible.\n\nWith this project, we hope to educate customers on how they can help reduce UCO waste while bringing attention to the negative effects of pouring away UCO.​"
          }
          callToAction={
            <div className="mt-4 sm:mt-0">
              <Link to={signUpLink}>
                <Button className="w-48" type="normal" text={"Sign Up"} />
              </Link>
            </div>
          }
        />
        <RightContentCard
          image={DoorImage}
          title="About Arus Oil"
          content={
            "Arus Oil (MyProtech Sdn. Bhd.) started as a pilot project for the middle-class in the SS14 community. Since 2017, residents of SS14 have been disposing of their used cooking oil (UCO) in a collection drum at the park nearby. \n\n This project was organised in collaboration with Kawasan Rukun Tetangga (KRT). The survey shows that most of the family households in SS14 cook frequently. Thus, the idea of recycled used cooking oil is born. Instead of throwing your used cooking oil away, you are able to earn cashback by selling it.​"
          }
        />
        <LeftContentCard
          image={AboutBiodiesel}
          title="What Happen To Your Used Cooking Oil"
          content={
            "Your used cooking oil will be exported to Netherlands & UK treatment plants where it will be converted to ASTM B-20 Biodiesel grade. There are multiple types of biodiesel which are segregated to \n\n- B-5 grade ( Used in light duty diesel vehicles )\n- B-20 grade ( Used in diesel vehicles or generators )\n- B-100 grade ( Heavy vehicles eg. Trains & aeroplane )"
          }
        />
        <SubscribeAction language={Language.EN} link={signUpLink} />
        <Footer />
      </div>
    )
  }
}

const mapStateToProps = (state: any) => {
  return {
    promotionStore: state.promotionStore,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    updatePromotionCode: (promoCode: string) =>
      dispatch(updatePromotionCode(promoCode)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PetronasPage)
